<script setup lang="ts">
const { isMobile } = useBreakpoint()
defineSlots<{ default(): any }>()
</script>

<template>
  <div class="flex min-h-screen flex-col">
    <div v-if="!isMobile" class="relative">
      <header
        class="sticky top-0 z-fixed flex h-[72px] items-center justify-between border-b border-gray-200 bg-white px-4 sm:px-6"
      >
        <TheHeaderLogo />
        <TheHeaderDesktopNavigationSwitch class="mr-0" />
      </header>
    </div>
    <div>
      <slot />
    </div>
  </div>
</template>
